import momentTimezone from 'moment-timezone'

import { APITYPES } from 'types/apitypes'
import { hourMinuteFormat } from 'utils/dateUtils'

import { cities } from './getRows'


export const columns = ([
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
    align: 'left',
    map: (item: APITYPES.DoerMonitoring) => cities[item.city.id - 1],
  },
  {
    title: 'Район',
    dataIndex: 'district',
    key: 'district',
    align: 'left',
  },
  {
    title: 'Адрес',
    dataIndex: 'carwash_address',
    key: 'carwash_address',
    align: 'left',
  },
  {
    title: 'Время',
    dataIndex: 'shift_type',
    key: 'shift_type',
    align: 'center',
    map: (item: APITYPES.DoerMonitoring) => item.shift_type.begin.slice(0, 5) + ' - ' + item.shift_type.end.slice(0, 5),
  },
  {
    title: 'Начало',
    dataIndex: 'started',
    key: 'started',
    align: 'left',
    map: (item: APITYPES.DoerMonitoring) => momentTimezone(item.started)
      .tz(item.city?.timezone, true)
      .format(hourMinuteFormat),
  },
  {
    title: 'Конец',
    dataIndex: 'ended',
    key: 'ended',
    align: 'left',
    map: (item: APITYPES.DoerMonitoring) => momentTimezone(item.ended)
      .tz(item.city?.timezone, true)
      .format(hourMinuteFormat),
  },
  {
    title: 'ФИО Исполнителя',
    dataIndex: 'doer',
    key: 'doer',
    align: 'left',
    map: (item: APITYPES.DoerMonitoring) => item?.doer?.name ?? '',
  },
  {
    title: 'Первая смена',
    dataIndex: 'is_first_shift',
    key: 'is_first_shift',
    align: 'center',
    width: '15%',
    map: (item: APITYPES.DoerMonitoring) => item.is_first_shift ? 'Да' : '',
  },
  {
    title: 'Количество операции',
    dataIndex: 'operation_count',
    key: 'operation_count',
    align: 'center',
    width: '15%',
  },
])

export const getColumns = () => columns
